import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { about } from '../constant';

import { useNavigate } from 'react-router-dom';

const Logo = ({ className }) => {
	const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768); // 768px is a common breakpoint for "md" in responsive design
	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(window.innerWidth >= 768);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const navigate = useNavigate();

	const goHome = () => {
		navigate('/');
	};

	return (
		<div className="rounded-full bg-black w-32 h-32 overflow-hidden flex items-center mx-auto">
			{about.map((intro) => (
				<React.Fragment key={intro.id}>
					{isDesktop ? (
						<div
							className={classNames(
								'logo mx-auto cursor-pointer h-24',
								className
							)}
							onClick={goHome}
						>
							<video
								loop
								muted
								autoPlay
								playsInline
								className="w-full h-full object-cover"
							>
								<source src={intro.logoMov} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</div>
					) : (
						<div
							className={classNames(
								'logo mx-auto cursor-pointer w-12',
								className
							)}
							onClick={goHome}
						>
							<img
								src={intro.logo}
								alt="logo"
								className="w-full h-full object-cover"
							/>
						</div>
					)}
				</React.Fragment>
			))}
		</div>
	);
};

export default Logo;
